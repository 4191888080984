import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, FloatingLabel, Form, InputGroup, Spinner } from 'react-bootstrap';

import { clsx } from 'clsx';

import MerchantRadioBox from '../../components/MerchantRadioBox';
import api from '../../utils/api';
import { gatewayHost } from '../../configuration';
import CircleText from '../..//components/CircleText';
import CentralFrame from '../../components/CentralFrame';
import ErrorModal from '../../components/Modals/Error';
import CopyButton from 'shared-components/components/CopyButton';
import { environment } from 'shared-components/configuration';
import InfoModal from 'components/Modals/Info';

import style from './style.module.scss';

const TrySettle = () => {
  const pidRef = useRef<HTMLInputElement>(null);
  const [merchants, setMerchants] = useState<any>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(false);
  const [action, setAction] = useState(-1);
  const [shortUrl, setShortUrl] = useState('');
  const [pidVisible, setPidVisible] = useState(false);
  const [currentMerchant, setCurrentMerchant] = useState('');
  const [withdrawModal, setWithdrawalModal] = useState<boolean>(false);
  const [withdrawData, setWithdrawData] = useState<any>({});
  const buttonText = useMemo(() => {
    if (busy) {
      return <Spinner />;
    }
    return 'Make Payment';
  }, [busy]);

  const formSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      const target = e.target as typeof e.target & {
        merchant: { value: string };
        currency: { value: string };
        amount: { value: string };
        withdrawiban: { value: string };
        pid: { value: string };
        usetoken: { checked: boolean };
        kycverify: { checked: boolean };
      };
      const merchant = target.merchant.value.split(',');
      if (Number.isNaN(Number.parseFloat(target.amount.value))) {
        console.log('ERROR HERE');
        return;
      }
      setBusy(true);
      const pid = target?.pid?.value;
      const clientId = pid !== undefined && pid.length > 0 ? pid : '';
      const kycVerify = target.kycverify.checked;
      if (action === 1 || action === 2 || action === 3) {
        api
          .requestRefID(
            merchant[1],
            '',
            target.amount.value,
            target.currency.value,
            clientId.length === 0 ? undefined : clientId,
            kycVerify
          )
          .then((response) => {
            setBusy(false);
            let generatedUrl = '';
            const useToken = target.usetoken.checked;
            const host = action === 3 ? 'http://localhost:9001/' : gatewayHost;
            generatedUrl =
              `${host}${response.data.createPi.challenge}?redirect=${window.location}` + `&token=${useToken}`;

            if (action === 1 || action === 3) {
              window.location.href = generatedUrl;
            } else if (action === 2) {
              fetch(`https://tinyurl.com/api-create.php?url=${generatedUrl}`).then(async (response) => {
                setShortUrl(await response.text());
              });
            }
          });
      } else if (action === 4) {
        const random = Math.floor(Math.random() * 90_000_000) + 10_000_000;
        api
          .withdraw(
            merchant[1],
            '',
            target.amount.value,
            target.currency.value,
            clientId,
            `BS${random}`,
            target.withdrawiban.value
          )
          .then((response) => {
            setBusy(false);
            setWithdrawalModal(true);
            setWithdrawData(response.data);
          });
      }
    },
    [action]
  );

  const handleMerchantChange = useCallback((event: any) => {
    const merchant = event.currentTarget.value ?? event.currentTarget.ariaValueText;
    setCurrentMerchant(merchant);
  }, []);

  useEffect(() => {
    setPidVisible(true);
    setTimeout(function () {
      api.merchantList().then((response) => {
        setMerchants(response?.data);
        setDataLoaded(true);
      });
    }, 500);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pidVisible) {
      pidRef?.current?.focus();
    }
  }, [pidVisible]);

  return (
    <>
      <InfoModal
        title={'Withdrawal status'}
        visible={withdrawModal}
        handleClose={() => setWithdrawalModal(false)}
        onOk={() => setWithdrawalModal(false)}
        size="xl"
      >
        Widthdraw {withdrawData?.withdraw?.success === true ? 'SUCCESS' : 'FAIL'}
        <br />
        INFO FROM SERVER:
        <br />
        message: {withdrawData?.withdraw?.message}
        <br />
        transaction ID: {withdrawData?.withdraw?.transactionId}
      </InfoModal>

      <ErrorModal
        show={error}
        handleClose={() => {
          setError(false);
        }}
      />
      <center className="mt-5">
        <h1 className={style.h1}>Try Settlement and donate to a good cause</h1>
        <p className={style.secondline}>
          Make a quick and secure donation directly from your bank account using open
          <br /> banking- no fees attached.
        </p>
        <CentralFrame>
          <Form onSubmit={formSubmit}>
            <CircleText blueText="1">Pick a charity</CircleText>

            <div className={clsx(dataLoaded ? style.visible : style.hidden, style.marchants_list)}>
              {Array.isArray(merchants) &&
                merchants.length > 0 &&
                merchants.map((element: any, i) => {
                  const value = [element.name, element.email];
                  return (
                    <MerchantRadioBox
                      key={i}
                      group={'merchant'}
                      value={value}
                      iban={element.name}
                      email={element.email}
                      onChange={handleMerchantChange}
                      defaultChecked={i === 0}
                      checked={currentMerchant === value.toString() || (currentMerchant === '' && i === 0)}
                    />
                  );
                })}
            </div>
            <div className={dataLoaded ? style.visible : style.hidden}>
              <CircleText blueText="2">Choose an amount</CircleText>
              <InputGroup className="mt-3">
                <FloatingLabel controlId="contactSalesMessage" label="Enter amount" className={style.flabel}>
                  <Form.Control
                    autoFocus
                    name="amount"
                    type="number"
                    placeholder="Message"
                    className={style.input}
                    step={0.01}
                    defaultValue={2}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Enter valid amount</Form.Control.Feedback>
                </FloatingLabel>
                <InputGroup.Text className={style.suffix}>
                  <Form.Select aria-label="Currency" name="currency">
                    <option value="SEK">SEK</option>
                    <option value="EUR">EUR</option>
                  </Form.Select>
                </InputGroup.Text>
              </InputGroup>{' '}
              <br />
              <Form.Check // prettier-ignore
                type="switch"
                id="kycVerifyCheckbox"
                label="Manual KYC verify"
                name="kycverify"
                defaultChecked={false}
              />
              <Form.Check // prettier-ignore
                type="switch"
                id="addTokenCheckbox"
                label="Use token"
                name="usetoken"
                defaultChecked={false}
              />
              <Form.Check // prettier-ignore
                type="switch"
                id="pidCheckbox"
                label="Provide PersonID data"
                name="pidenable"
                defaultChecked={true}
                onClick={(e) => {
                  setPidVisible(e.currentTarget.checked);
                }}
              />
              {pidVisible === true && (
                <FloatingLabel controlId="pid" label="Person ID">
                  <Form.Control
                    type="text"
                    size="sm"
                    placeholder="Person ID"
                    name="pid"
                    ref={pidRef}
                    defaultValue={'197908269314'}
                  />
                </FloatingLabel>
              )}
              <FloatingLabel controlId="withdrawiban" label="Withdraw IBAN">
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder="Withdraw IBAN"
                  name="withdrawiban"
                  defaultValue={'SE5372368567512649118983'}
                />
              </FloatingLabel>
              <div className={style.info}>
                By using the service, I accept Infinipi Terms of Use, and confirm that I have read Infinipi Privacy
                Statement.
              </div>
              <center>
                <Button
                  variant="primary"
                  name="shortUrl"
                  value={1}
                  size="sm"
                  type="submit"
                  disabled={busy}
                  onClick={() => {
                    setAction(2);
                  }}
                  style={{ width: '140px' }}
                >
                  Create Link
                </Button>

                <Button
                  variant="primary"
                  name="pay"
                  value={1}
                  size="sm"
                  type="submit"
                  disabled={busy}
                  onClick={() => {
                    setAction(1);
                  }}
                  style={{ width: '140px', margin: '5px' }}
                >
                  {buttonText}
                </Button>

                <Button
                  variant="primary"
                  name="withdraw"
                  value={1}
                  size="sm"
                  type="submit"
                  disabled={busy}
                  onClick={() => {
                    setAction(4);
                  }}
                  style={{ width: '140px', margin: '5px' }}
                >
                  Withdraw
                </Button>

                {(environment === 'staging' || environment === 'dev' || environment === 'uat') && (
                  <Button
                    variant="primary"
                    name="pay"
                    value={1}
                    size="sm"
                    type="submit"
                    disabled={busy}
                    onClick={() => {
                      setAction(3);
                    }}
                    style={{ width: '140px', margin: '5px' }}
                  >
                    localhost debug
                  </Button>
                )}
              </center>
              {shortUrl && (
                <>
                  <InputGroup className="mt-3">
                    <FloatingLabel controlId="shorturl" label="Payment link" className={style.flabel}>
                      <Form.Control autoFocus type="text" value={shortUrl} />
                    </FloatingLabel>
                    <InputGroup.Text className={style.suffix}>
                      <CopyButton value={shortUrl} />
                    </InputGroup.Text>
                  </InputGroup>
                  <br />
                </>
              )}
            </div>
          </Form>
        </CentralFrame>
      </center>
    </>
  );
};
export default TrySettle;
